import * as Sentry from '@sentry/nextjs';
import jwt_decode, { type JwtPayload } from 'jwt-decode';

import { Auth0Service, type OAuthTokenResponse } from '@/services/auth0';
import fetchJson from '@/utils/fetchJson';
import { userParse } from '@/utils/user';

const oneDay = 86400000;
export interface DecodedUser extends JwtPayload {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
}

export interface DecodedUserAccessToken extends JwtPayload {
  'http://dev-suppliers-uselisto.com/roles': string[];
  'http://dev-suppliers-uselisto.com/user_metadata': {
    name?: string;
    company_id: number;
  };
  iss: 'https://dev-suppliers-uselisto.us.auth0.com/';
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  gty: string;
}

export class JWTService {
  static decodeUser = (auth0User: OAuthTokenResponse): DecodedUser => {
    return jwt_decode(auth0User.access_token);
  };

  static decodeUserRoles = (accessToken: string): string[] => {
    const decodedAccessToken: any = jwt_decode(accessToken);
    return decodedAccessToken[process.env.NEXT_PUBLIC_AUTH0_ROLES_URL || 'http://dev-suppliers-uselisto.com/roles'];
  };

  static isValidToken = (): boolean => {
    const storedUser: string | null = localStorage.getItem('user');
    if (!storedUser) {
      return false;
    }
    const user: User = JSON.parse(storedUser || '');
    const token = user.accessToken;
    const decodedToken: DecodedUser = jwt_decode(token);
    const currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    } else {
      return true;
    }
  };

  static getManagmentToken = async (responseToken: OAuthTokenResponse) => {
    const response = await Auth0Service._oauthMngmtToken();
    if (responseToken && response) {
      localStorage.setItem('mngmtAccessToken', JSON.stringify(response.access_token));
      const decodedUserData = JWTService.decodeUser(responseToken);
      const user = userParse(decodedUserData, responseToken.access_token, responseToken.refresh_token);
      localStorage.setItem('user', JSON.stringify(user));
      await fetchJson<User>('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
    }
  };

  static refreshToken = async () => {
    try {
      const currentTime = (Date.now() + oneDay) / 1000;
      const storedUser: string | null = localStorage.getItem('user');
      const user: User = JSON.parse(storedUser || '');
      if (user.expire && user.expire < currentTime) {
        const response = await Auth0Service._refreshToken(user.refreshToken);
        await this.getManagmentToken(response);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };
}
