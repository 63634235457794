import { Auth0Service } from '@/services/auth0';
import { type DecodedUser } from '@/services/jwt';

export const userParse = (decodedUser: DecodedUser, accessToken: string, refreshToken: string): User => ({
  email: '',
  name: decodedUser.name,
  accessToken,
  showOnboarding: false,
  id: decodedUser.sub,
  roles: Auth0Service.UserRoles(accessToken),
  refreshToken,
  expire: decodedUser.exp,
});
