import React from 'react';

import { UserActions } from '@/state/user/actions';
import { UserState, initialUserState } from '@/state/user/state';

export const UserContext = React.createContext<{
  state: UserState;
  dispatch: React.Dispatch<UserActions>;
}>({
  state: initialUserState,
  dispatch: () => undefined,
});
