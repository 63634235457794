import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQuery,
  type UseQueryResult,
  type UseQueryOptions,
} from 'react-query';

import Endpoints from '@/constants/Endpoints';
import { QueryTypes } from '@/constants/QueryTypes';
import { axiosAuth0Instance } from '@/services/api';
import { JWTService } from '@/services/jwt';

interface PasswordlessStartFormData {
  client_id: string;
  client_secret?: string; // For Regular Web Applications
  connection: 'email' | 'sms';
  email?: string; // set for connection=email
  phone_number?: string; // set for connection=sms
  send?: 'link' | 'code'; // if left null defaults to link
  authParams?: {
    // any authentication parameters that you would like to add
    scope?: string; // used when asking for a magic link
    state?: string; // used when asking for a magic link, or from the custom login page
  };
}

interface PasswordlessStartResponse {
  _id: string;
  phone_number: string;
  phone_verified: boolean;
  request_language: string; // en-US,en;q=0.9
}

interface OAuthTokenFormData {
  grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp' | 'refresh_token';
  client_id: string;
  client_secret?: string; // only for web apps, native apps don’t have a client secret
  username?: string; // or "<phone number>"
  otp?: string;
  realm?: 'email' | 'sms'; // or "sms"
  audience?: string; // in case you need an access token for a specific API
  scope?: string; // "openid" | "profile" | "email" whatever scopes you need
  refresh_token?: string;
}

export interface OAuthTokenResponse {
  access_token: string;
  refresh_token: string;
  id_token: string; // Too much personal information, maybe this will be not sent
  token_type: string;
  expires_in: number;
}

interface UpdateUserFormData {
  blocked?: boolean;
  email_verified?: boolean;
  email?: string;
  phone_number?: string;
  phone_verified?: boolean;
  user_metadata?: object;
  app_metadata?: object;
  given_name?: string;
  family_name?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  verify_email?: boolean;
  verify_phone_number?: boolean;
  password?: string;
  connection?: string;
  client_id?: string;
  username?: string;
}

interface UpdateUserResponse {
  user_id: string;
  email: string;
  email_verified: boolean;
  username: string;
  phone_number: string;
  phone_verified: boolean;
  created_at: string;
  updated_at: string;
  identities: Array<{
    connection: string;
    user_id: string;
    provider: string;
    isSocial: boolean;
  }>;
  app_metadata: object;
  user_metadata: object;
  picture: string;
  name: string;
  nickname: string;
  multifactor: string[];
  last_ip: string;
  last_login: string;
  logins_count: number;
  blocked: boolean;
  given_name: string;
  family_name: string;
}

interface OAuthMngmtTokenFormData {
  grant_type: string;
  client_id: string;
  client_secret: string; // only for web apps, native apps don’t have a client secret
  audience: string; // in case you need an access token for a specific API
}

export interface OAuthMngmtTokenResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

interface RoleItem {
  description: string;
  id: string;
  name: string;
}

export interface OAuthUserRolesResponse extends Array<RoleItem> {}

interface setUserRolesFormData {
  roles: string[];
  userId?: string;
}

export class Auth0Service {
  /**
   * @description Sends sms OTP
   * @param username
   */
  static _passwordlessStart = async (username: string): Promise<PasswordlessStartResponse> => {
    const formData: PasswordlessStartFormData = {
      connection: 'sms',
      client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
      phone_number: username,
      send: 'code',
    };

    try {
      const response: any = (await axiosAuth0Instance.post(`${Endpoints.AUTH0_PASSWORDLESS_START}`, formData)).data;
      return response || null;
    } catch (error) {
      throw new Error(`Unknown error: ${JSON.stringify(error)}`);
    }
  };

  /**
   * @description Used to log-in based on OTP received from sms.
   * *WARNING: You cannot use this endpoint from Single Page Applications.*
   * @param phone
   * @param code
   */
  static _oauthToken = async (phone: string, code: string): Promise<OAuthTokenResponse> => {
    const formData: OAuthTokenFormData = {
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
      client_secret: process.env.NEXT_PUBLIC_AUTH0_CLIENT_SECRET || '',
      username: phone,
      otp: code,
      realm: 'sms', // or "sms"
      scope: 'offline_access', // whatever scopes you need
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE, // THIS MUST BE SET OTHERWISE WE WILL RECEIVE AN OPAQUE TOKEN (CAN'T BE DECODED)
    };

    try {
      const response: OAuthTokenResponse = (await axiosAuth0Instance.post(`${Endpoints.AUTH0_OAUTH_TOKEN}`, formData))
        .data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  /**
   * @description Get user metadata using auth0 user id
   * @param userId
   */
  private static readonly _getUserProfile = async (userId: string): Promise<Auth0Profile> => {
    try {
      const response: Auth0Profile = (await axiosAuth0Instance.get(`${Endpoints.AUTH0_UPDATE_USER_METADATA}${userId}`))
        .data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  public static useUserProfile = (
    userId: string,
    { ...opts }: UseQueryOptions<Auth0Profile, Error>,
  ): UseQueryResult<Auth0Profile, Error> => {
    return useQuery<Auth0Profile, Error>(QueryTypes.PROFILE, async () => await this._getUserProfile(userId), {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...opts,
    });
  };

  /**
   * @description Patch user metadata using auth0 user id
   * @param userId
   * @param data
   */
  static _patchUserMetaData = async (userId: string, data: object, extraData?: any): Promise<UpdateUserResponse> => {
    const formData: UpdateUserFormData = {
      user_metadata: data,
      // client_id: Constants.manifest && Constants.manifest.extra ? Constants.manifest.extra.auth0ClientID || '' : '',
    };

    if (extraData) {
      formData.name = extraData.name;
      formData.given_name = extraData.given_name;
      formData.family_name = extraData.family_name;
      formData.picture = extraData?.picture;
    }

    try {
      const response: UpdateUserResponse = (
        await axiosAuth0Instance.patch(`${Endpoints.AUTH0_UPDATE_USER_METADATA}${userId}`, formData)
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  /**
   * @description Used to log-in based on OTP received from sms.
   * *WARNING: You cannot use this endpoint from Single Page Applications.*
   */
  static _oauthMngmtToken = async (): Promise<OAuthMngmtTokenResponse> => {
    const formData: OAuthMngmtTokenFormData = {
      grant_type: 'client_credentials',
      client_id: process.env.NEXT_PUBLIC_AUTH0_MNGMNT_CLIENT_ID || '',
      client_secret: process.env.NEXT_PUBLIC_AUTH0_MNGMNT_CLIENT_SECRET || '',
      audience: process.env.NEXT_PUBLIC_AUTH0_MNGMNT_AUDIENCE || '',
    };
    try {
      const response: OAuthMngmtTokenResponse = (
        await axiosAuth0Instance.post(`${Endpoints.AUTH0_OAUTH_TOKEN}`, formData)
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  static UserRoles(accessToken: string): string[] {
    return JWTService.decodeUserRoles(accessToken);
  }

  /**
   * @description Get user roles permissions
   */
  private static readonly _getUserRoles = async (): Promise<OAuthUserRolesResponse> => {
    try {
      const response: OAuthUserRolesResponse = (await axiosAuth0Instance.get(`${Endpoints.AUTH0_ROLES}`)).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static useMutationGetUserRoles = (
    options?: UseMutationOptions<any, Error, any>,
  ): UseMutationResult<any, Error, any> => {
    return useMutation<any, Error, any>(async () => await Auth0Service._getUserRoles(), options);
  };

  /**
   * @description Set user roles
   * @param formData
   */
  static _setUserRoles = async (formData: setUserRolesFormData): Promise<OAuthUserRolesResponse> => {
    const { userId } = formData;
    delete formData.userId;

    try {
      const response: OAuthUserRolesResponse = (
        await axiosAuth0Instance.post(`${Endpoints.AUTH0_UPDATE_USER_METADATA}${userId}/roles`, formData)
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static useSetUserRoles = (
    options?: UseMutationOptions<any, Error, setUserRolesFormData>,
  ): UseMutationResult<any, Error, setUserRolesFormData> => {
    return useMutation<any, Error, setUserRolesFormData>(
      async inviteSupplierFormData => await Auth0Service._setUserRoles(inviteSupplierFormData),
      options,
    );
  };

  /**
   * @description Used to log-in based on OTP received from sms.
   * *WARNING: You cannot use this endpoint from Single Page Applications.*
   * @param refreshToken
   */
  static _refreshToken = async (refreshToken: string): Promise<OAuthTokenResponse> => {
    const formData: OAuthTokenFormData = {
      grant_type: 'refresh_token',
      client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
      client_secret: process.env.NEXT_PUBLIC_AUTH0_CLIENT_SECRET || '',
      refresh_token: refreshToken,
    };

    try {
      const response: OAuthTokenResponse = (await axiosAuth0Instance.post(`${Endpoints.AUTH0_OAUTH_TOKEN}`, formData))
        .data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  /**
   * @description Get users by company id
   * @param companyId
   */
  private static readonly _getUsersByCompanyWithDomain = async (companyId: number): Promise<Auth0Profile> => {
    try {
      const response: Auth0Profile = (
        await axiosAuth0Instance.get(`${Endpoints.AUTH0_UPDATE_USERS}`, {
          params: {
            q: `user_metadata.company_id:${companyId} AND _exists_:user_metadata.restaurants_domain`,
            search_engine: 'v3',
          },
        })
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  public static useGetUsersByCompanyWithDomain = (
    companyId: number,
    { ...opts }: UseQueryOptions<Auth0Profile, Error>,
  ): UseQueryResult<any, Error> => {
    return useQuery<any, Error>(
      QueryTypes.USERS_BY_COMPANY_WITH_DOMAIN,
      async () => await this._getUsersByCompanyWithDomain(companyId),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        ...opts,
      },
    );
  };

  private static readonly _getUsersByCompany = async (companyId: number): Promise<Auth0Profile[]> => {
    try {
      const response: Auth0Profile[] = (
        await axiosAuth0Instance.get(`${Endpoints.AUTH0_UPDATE_USERS}`, {
          params: {
            q: `user_metadata.company_id:${companyId}`,
            search_engine: 'v3',
          },
        })
      ).data;
      return response || null;
    } catch (error: any) {
      throw new Error(error.error_description);
    }
  };

  public static useGetUsersByCompany = (
    companyId: number,
    { ...opts }: UseQueryOptions<Auth0Profile[], Error>,
  ): UseQueryResult<any, Error> => {
    return useQuery<any, Error>(QueryTypes.USERS_BY_COMPANY, async () => await this._getUsersByCompany(companyId), {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...opts,
    });
  };
}
