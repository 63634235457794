'use client';

import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';

import { JWTService } from '@/services/jwt';
import fetchJson from '@/utils/fetchJson';

export default function useUser({ redirectTo = '', redirectIfFound = false, isLanding = false } = {}) {
  const router = useRouter();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const isValidToken = JWTService.isValidToken();
    if (redirectTo && redirectIfFound && user?.accessToken && isValidToken) {
      router.push(redirectTo);
      return;
    }

    if (!isLanding && redirectTo === '' && user?.accessToken === '' && !isValidToken) {
      router.push('/');
    }
  }, [user, redirectTo, redirectIfFound, isLanding]);

  const fetchUser = useCallback(async (route: string = '/api/user', method: string = 'GET', body?: object) => {
    const fetchedUser = await fetchJson<User>(route, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    setUser(fetchedUser);
    return fetchedUser;
  }, []);

  return { user, fetchUser };
}
