export enum UserActionTypes {
  SetUser,
  SetUserToken,
  SetUserShowOnboarding,
}

export interface SetUser {
  type: UserActionTypes.SetUser;
  payload: User;
}

export interface SetUserToken {
  type: UserActionTypes.SetUserToken;
  payload: { accessToken: string };
}

export interface SetUserShowOnboarding {
  type: UserActionTypes.SetUserShowOnboarding;
  payload: { showOnboarding: boolean };
}

export type UserActions = SetUser | SetUserToken | SetUserShowOnboarding;
