export interface UserState {
  user: User;
}

export const initialUserState: UserState = {
  user: {
    email: '',
    name: '',
    accessToken: '',
    showOnboarding: false,
    id: '',
    roles: [],
    refreshToken: '',
  },
};
